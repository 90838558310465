import { MDBIcon } from "mdb-react-ui-kit";

import "./social.css";

export default function SocialFollow() {
  return (
    <div className="social-container">
      <a
        href="https://www.linkedin.com/in/hadi-asemi/"
        className="LinkedIn social-icon" style={{textDecoration:'none'}}
      >
        <MDBIcon fab icon="linkedin" size='4x'  />
      </a>
      <a href="mailto:hadi@hadiasemi.com" className="Gmail social-icon" style={{textDecoration:'none'}}>
        <MDBIcon fas icon="envelope-square" size="4x" />

      </a>
     
      <a href="https://github.com/Hadiasemi" className="Github social-icon" style={{textDecoration:'none'}}>

        <MDBIcon fab icon="github-square" size="4x" />
        {/* <FontAwesomeIcon icon={faGithub} size="4x" /> */}
      </a>
    </div>
  );
}
