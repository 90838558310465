import CalPoly from "../Image/CalPoly.png";
import uav from "../Image/UAV1.jpg";
import asl from "../Image/asl.jpg";
import mips from "../Image/mips.png";
import prediabetic from "../Image/prediabetic.png";
import subdomain from "../Image/subdomain1-1.jpeg";
import virtual from "../Image/virtual.png";

export const data = [
  {
    id: 1,
    image: asl,
    title:
      "American Sign Language Translator Web Application (Python) | September 2022 - December 2022",
    data: [
      "Build backend with Fast API and SQL-light",
      "Using Convolutional Neural Network (CNN) from Kaggle data",
      "Using 3 layers interspersed with batch normalization, max pooling, and dropout layers",
    ],
    link:
      "https://github.com/Hadiasemi/American-Sign-Language-Translator-Web-Application",
  },
  {
    id: 2,
    image: subdomain,
    title: "Build Subdomain Brute Forcing (Golang) | June 2022 - July 2022",
    data: ["Developed a parallel subdomain brute forcing"],
    link: "https://github.com/Hadiasemi/subdomainBruteForce",
  },
  {
    id: 3,
    image: uav,
    title: "Pentesting UAV Security and Privacy | Jan 2021- April 2021",
    data: [
      "Developed a threat model on the security and privacy of UAVs",
      "Pentesting on the vulnerabilities of UAVs, such as port vulnerabilities, man-in-the-middle attack, and jamming the Wifi signal",
    ],
    link: "",
  },
  {
    id: 4,
    image: CalPoly,
    title:
      "Get the Cal Poly Catalog on Terminal for Each Major Acronyms (Golang) | Jun 2022 - July 2022",
    data: [
      "Built a script on the Cal Poly catalog in Golang.",
      "Get class, names, and units in your terminal without going to the school catalog website.",
      "I compiled this for Windows x64 and Mac so that if you don’t have golang on your computer still able to use my script.",
    ],
    link: "https://github.com/Hadiasemi/Calpoly-classes",
  },
  {
    id: 5,
    image: prediabetic,
    title:
      "Predicting Pre-Diabetic Using Machine Learning in Farm Workers (Python) | September 2020 - October 2020",
    data: [
      "Accurately predicted the likelihood of diabetes based on data provided by Dr. Angelos Sikalidis.",
      "Built an AI system for dietary recommendations based on the measurement of patients’ vitamin level.",
    ],
    link: "https://github.com/Hadiasemi/Diabetics-AI",
  },
  {
    id: 6,
    image: mips,
    title: "MIPS Assembler (Java, MIPS) | September 2020 - October 2020",
    data: [
      "Developed Java program to automatically convert MIPS assembly instructions into binary",
      "Executed machine-level instructions through register and memory simulation",
      "Improved execution speed of existing hardware through algorithm analysis and code restructuring",
    ],
    link: "",
  },
  {
    id: 7,
    image: virtual,
    title: "Virtual World Game (Java) | June 2020 - August 2020",
    data: [
      "Refactored existing code base to follow object-oriented principles through elimination of procedural programming and creation of abstract classes",
      "Produced complete UML diagrams displaying class inheritance and method implementation",
      "Created methods improving entity movement accuracy and object collision detection",
    ],
    link: "",
  },
];
