import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

import { motion } from "framer-motion";

import { data } from "./Data";

export default function Card() {
  return (
    <MDBContainer>
      <br />
      <br />
      <br />
      <br />
      {renderData()}
    </MDBContainer>
  );
}

const renderData = () => {
  return data.map(({ id, image, title, data, link }) => {
    return (
      <MDBRow key={id}>
        <MDBCol md="5" className="mb-5">
          <motion.img
            src={image}
            style={{
              heigh: "60%",
              width: "60%",
              marginLeft: "100px",
              display: "center",
            }}
            className="img-fluid rounded"
            alt=""
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, stiffness: 0 }}
          />
        </MDBCol>
        <MDBCol md="7">
          <motion.h3
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, stiffness: 0 }}
          >
            {title}
          </motion.h3>
          <motion.ul
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, stiffness: 0 }}
          >
            {data.map((i) => (
              <li key={i}>{i}</li>
            ))}
            {link !== "" ? (
              <li>
                Project code: <a href={link}>Github</a>{" "}
              </li>
            ) : (
              ""
            )}
          </motion.ul>
        </MDBCol>
        <br />
        <br />
      </MDBRow>
    );
  });
};
