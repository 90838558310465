import React from "react";

import { MDBFooter, MDBContainer, MDBIcon, MDBBtn } from "mdb-react-ui-kit";

export default function App() {
  return (
    <MDBFooter className="bg-dark text-center text-white">
      <MDBContainer className="p-4 pb-0">
        <section className="mb-4">
          <MDBBtn
            outline
            color="light"
            style={{ backgroundColor: "#333333" }}
            floating
            className="m-1"
            href="https://github.com/Hadiasemi"
            role="button"
          >
            <MDBIcon fab icon="github" />
          </MDBBtn>
          <MDBBtn
            outline
            color="light"
            style={{ backgroundColor: "#0082ca" }}
            floating
            className="m-1"
            href="https://www.linkedin.com/in/hadi-asemi/"
            role="button"
          >
            <MDBIcon fab icon="linkedin-in" />
          </MDBBtn>
          <MDBBtn
            outline
            color="light"
            style={{ backgroundColor: "#dd4b39" }}
            floating
            className="m-1"
            href="mailto:hadi@hadiasemi.com"
            role="button"
          >
            <MDBIcon far icon="envelope" />
          </MDBBtn>
        </section>
      </MDBContainer>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2023 Copyright:
        <a
          style={{ marginLeft: "10px" }}
          className="text-white"
          href="https://hadiasemi.com/"
        >
          hadiasemi.com
        </a>
      </div>
    </MDBFooter>
  );
}
