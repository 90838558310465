import React from "react";

import { MDBContainer, MDBRow } from "mdb-react-ui-kit";

import postlist from "../blog.json";
import Card from "../Components/Card";

export default function Blog() {
  return (
    <MDBContainer>
      <br />
      <br />
      <br />
      <MDBRow>
        <br />
        <br />
        <br />
        {postlist.map((data) => {
          return <Card data={data} key={data.id} />;
        })}
      </MDBRow>
    </MDBContainer>
  );
}
