import React from "react";

import "bootstrap/dist/css/bootstrap.css";

import { Route, Routes } from "react-router-dom";

import "./App.css";

import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Project from "./Pages/Project";
import Resume from "./Pages/Resume";
import Blog from "./Pages/Blog";
import Post from "./Pages/Post";

// HelmetProvider.canUseDOM = false;

function App() {
  return (
    <>
      {/* <Helmet>
                <meta charSet="utf-8" />
                <title>Portfolio</title>
                <link rel="canonical" href="https://hadiasemi.com" />
                <meta name="description" content="Cyber Scurity, Portfolio, Resume" />
    </Helmet> */}
      <Navbar />

      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Projects" element={<Project />} />
          <Route path="/Resume" element={<Resume />} />
          <Route path="/Blogs" element={<Blog />} />
          <Route path="/Post/:props" element={<Post />} />
          {/* <Route path="/Resume" /> */}
        </Routes>
      </div>

      <Footer />
    </>
  );
}

export default App;
