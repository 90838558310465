import React, { useState } from "react";

import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
} from "mdb-react-ui-kit";
import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiFillFile,
  
} from "react-icons/ai";
import { ImBlog } from "react-icons/im";

import favicon from "../Image/favicon.ico";

export default function Navbar() {
  // const [showNavColor, setShowNavColor] = useState(false);
  const [showNavColorSecond, setShowNavColorSecond] = useState(false);
  // const [showNavColorThird, setShowNavColorThird] = useState(false);

  return (
    <>
      <MDBNavbar expand="lg"  style={{}} dark bgColor="dark" fixed="top">
        <MDBContainer fluid>
          <MDBNavbarBrand
            className="mb-0 h1"
            href="/"
            style={{ fontFamily: ["Roboto", "sans-serif"], fontSize: "30px" }}
          >
            <img src={favicon} height="50" alt="" loading="lazy" />
            Hadi Asemi
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarColor02"
            aria-controls="navbarColor02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavColorSecond(!showNavColorSecond)}
            // onClick={() => setShowNavRight(!showNavRight)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <MDBCollapse show={showNavColorSecond} navbar id="navbarColor02">
            <MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0 h5">
              <MDBNavbarItem className="active">
                <MDBNavbarLink
                  aria-current="page"
                  href="/"
                  style={{ fontFamily: ["Roboto", "sans-serif"] }}
                >
                 <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  href="/Resume"
                  style={{ fontFamily: ["Roboto", "sans-serif"] }}
                >
                  <AiFillFile style={{ marginBottom: "2px" }} />
                  Resume
                </MDBNavbarLink>
                {/* <MDBNavbarLink href={Resume21} style={{fontFamily: 'Lato'}}>Resume</MDBNavbarLink> */}
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  href="/Projects"
                  style={{ fontFamily: ["Roboto", "sans-serif"] }}
                >
                  <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                  Projects
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  href="/Blogs"
                  style={{ fontFamily: ["Roboto", "sans-serif"] }}
                >
                   <ImBlog style={{ marginBottom: "2px" }} />Blogs
                </MDBNavbarLink>
              </MDBNavbarItem>
              
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}
