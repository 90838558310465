import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

import React from "react";

import a from "../Image/a.png";
import b from "../Image/b.png";
import c from "../Image/c.png";

import "./social.css";

export default function Gallery() {
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol lg="4" md="12" className="mb-4">
          <img src={a} className="img-fluid rounded" alt="" />
	  <h4 style={{textAlign:'center', fontWeight: 'bold',}}>Cyber Security</h4>
        </MDBCol>

        <MDBCol lg="4" md="6" className="mb-4">
          <img
            src={b}
            className="img-fluid rounded-circle"
            alt="kkkk"
          />
	   <h4 style={{textAlign:'center', fontWeight: 'bold',}}>Software Engineer</h4>
        </MDBCol>

        <MDBCol lg="4" md="6" className="mb-4">
          <img
            src={c}
            className="img-fluid rounded-pill"
            alt=""
          />
	   <h4 style={{textAlign:'center', fontWeight: 'bold',}}>Privacy</h4>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
