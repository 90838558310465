import React from "react";

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";


import { motion } from "framer-motion";

import Resume21 from "../Components/Resume21.pdf";

import "./Resume.css";

export default function Resume() {
  return (
   
    <MDBContainer className="py-5">
      {/* ------------Education---------- */}
      <br />
      <br />
      <MDBRow>
        <MDBCol md="" offsetMd="2">
          <motion.h2
            className="fw-bold"
            style={{ marginLeft: "1.2rem" }}
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, stiffness: 0 }}
          >
            <span className="timeline-icon">
              <MDBIcon
                icon="building-columns"
                color="solid"
                size="sm"
                style={{ marginRight: "12px" }}
              />
            </span>
            Education
          </motion.h2>
          <motion.ul
            className="timeline-3"
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, stiffness: 0 }}
          >
            <li>
              <h3>
                MS in Computer Science California Polytechnic State University
              </h3>
              <p className="text-muted mb-2 fw-bold">
                {" "}
                September 2022- Current
              </p>
              <p className="mt-2">
                Focus on AI, Cyber Security, and privacy in vehicle networks
              </p>
            </li>
            <li>
              <h3>
                BS in Computer Science California Polytechnic State University{" "}
              </h3>
              <p className="text-muted mb-2 fw-bold">
                September 2019- May 2022
              </p>
              <p className="mt-2">
                • Member of AI Club, White Hat Security Club, and Color-Coded
                Club.
                <br />• Cal Poly Scholars award winner
              </p>
            </li>
          </motion.ul>
        </MDBCol>
      </MDBRow>

      <br />
      {/* ------------Skills---------- */}
      <MDBRow>
        <MDBCol md="8" offsetMd='2'>
          
          <motion.h2
            className="fw-bold"
            style={{ marginLeft: "1.2rem" }}
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, stiffness: 0 }}
          >
            <span className="timeline-icon">
              <MDBIcon
                icon="code"
                color="solid"
                size="sm"
                style={{ marginRight: "12px" }}
              />
            </span>
            Skills
          </motion.h2>
          <motion.p
            style={{ align: "center", marginLeft: "1.2rem" }}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, stiffness: 0 }}
          >
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/c.svg"
              style={{ marginRight: "12px" }}
            />
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/java.svg"
              style={{ marginRight: "12px" }}
            />
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/python.svg"
              style={{ marginRight: "12px" }}
            />
            
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/go.svg"
              style={{ marginRight: "12px" }}
            />
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/bash.svg"
              style={{ marginRight: "12px" }}
            />
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/reactjs.svg"
              style={{ marginRight: "12px" }}
            />
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/flask.svg"
              style={{ marginRight: "12px" }}
            />
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/linux.svg"
              style={{ marginRight: "12px" }}
            />
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/mysql.svg"
              style={{ marginRight: "12px" }}
            />
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/mongodb.svg"
              style={{ marginRight: "12px" }}
            />
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/docker.svg"
              style={{ marginRight: "12px" }}
            />
            <img
              width={"40px"}
              alt=" "
              src="https://raw.githubusercontent.com/rahulbanerjee26/githubAboutMeGenerator/main/icons/matlab.svg"
              style={{ marginRight: "12px" }}
            />
          </motion.p>
        </MDBCol>
      </MDBRow>
      <br />
      {/* ------------Work---------- */}
      <MDBRow>
        <MDBCol md="" offsetMd="2">
          <motion.h2
            className="fw-bold"
            style={{ marginLeft: "1.2rem" }}
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, stiffness: 0 }}
          >
            <span className="timeline-icon">
              <MDBIcon
                icon="briefcase"
                color="solid"
                size="sm"
                style={{ marginRight: "12px" }}
              />
            </span>
            {/* <FontAwesomeIcon icon={faBriefcase} style={{marginRight:'12px'}} /> */}
            Employment
          </motion.h2>
           <motion.ul
            className="timeline-3"
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, stiffness: 0 }}
          >
            <li>
              <h3>TA for Wireless Security Course </h3>

              <p className="text-muted mb-2 fw-bold">
                January 2023- Current
              </p>
              <span style={{ fontStyle: "italic", fontSize: "20px" }}>
                Teacher Assistant
              </span>

              <p className="mt-2">
                • Managing different teams
                <br />• Guiding students on how to use different pentesting tools
              </p>
            </li>
          
            <li>
              <h3>Strategic Research Initiatives Program (SRI) </h3>

              <p className="text-muted mb-2 fw-bold">
                Jun 2022- September 2022
              </p>
              <span style={{ fontStyle: "italic", fontSize: "20px" }}>
                Cyber Security (Intern)
              </span>

              <p className="mt-2">
                • Identified current IoT security challenges in Smart Home
                Applications
                <br />• Studied corresponding solution
              </p>
            </li>
            <li>
              <h3>Diabetic Outlet</h3>
              <p className="text-muted mb-2 fw-bold">
                Jun 2022- September 2022
              </p>
              <span style={{ fontStyle: "italic", fontSize: "20px" }}>
                Software Engineer (Intern)
              </span>

              <p className="mt-2">
                • Developed iPhone application with React Native by using
                WordPress Woocommerce API
              </p>
            </li>
            <li>
              <h3>WhiteFox Defense Technologies, Inc.</h3>
              <p className="text-muted mb-2 fw-bold">Jun 2021- March 2022</p>
              <span style={{ fontStyle: "italic", fontSize: "20px" }}>
                Software Engineer (Intern)
              </span>
              <p className="mt-2">
                • Developed the testing framework for frontend and documented
                the code with Sphinx
                <br />• Build an internal reporting system
              </p>
            </li>
            <li>
              <h3>
                Undergraduate Research Assistant | UC Merced ASPIRES Internship{" "}
              </h3>
              <p className="text-muted mb-2 fw-bold">Jun 2019- March 2019</p>
              <span style={{ fontStyle: "italic", fontSize: "20px" }}>
                Software Engineer (Intern)
              </span>
              <p className="mt-2">
                • Presented human-automation system principles research and
                developed a novel design for motorized inverted pendulum
                controllers
                <br />• Built an Android application and PID filter in Simulink
                to drive microcontroller and pendulum components.
              </p>
            </li>
          </motion.ul>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md="" offsetMd="2">
          <motion.a
            href={Resume21}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, stiffness: 0 }}
          >
            <MDBBtn color="primary" style={{ marginLeft: "1.2rem" }}>
              <MDBIcon icon="download" style={{ marginRight: "12px" }} />
              Download
            </MDBBtn>
          </motion.a>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
