import React from "react";

import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

import "bootstrap/dist/css/bootstrap.css";

import { motion } from "framer-motion";

import "../App.css";
import Gallery from "../Components/Gallery";
import SocialFollow from "../Components/SocialFollow";

export default function Home() {
  return (
    <div>
      <motion.div
        className="bg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, stiffness: 80 }}
      />
      <div className="container">
        <article>
          <MDBContainer>
            <br />
            <MDBRow>
              <MDBCol md="8">
                {/* <Wraper> */}
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3, stiffness: 80 }}
                >
                  <h1>About: </h1>
                  <p>
                    I am a cybersecurity enthusiast, specializing in application
                    security. I am currently pursuing my master’s degree in
                    computer science at California Polytechnic University, San
                    Luis Obispo.
                  </p>
                  {/* <br></br> */}
                  <p>
                    I am part of the WhiteHat cyber security club. I am
                    proficient in C, Java, Python, and Golang, and I just built
                    and managed two servers from the ground up, from security to
                    setting up websites, users, Linux environment, etc. I am
                    proficient in the Linux command line and use vim as my
                    developing environment. In addition, I write scripts to
                    automate my ethical penetration testing during my free time.
                    Every day, I try to update my knowledge to be at or above
                    industry standards.
                  </p>
                </motion.div>
                {/* </Wraper> */}
              </MDBCol>
              <MDBCol md="4" style={{ paddingLeft: "60px" }}>
                <motion.h1
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3, stiffness: 80 }}
                >
                  Contact Me:
                </motion.h1>
                <motion.div
                  whileInView={{ opacity: 1, x: 0 }}
                  initial={{ opacity: 0, x: 100 }}
                  transition={{ delay: 0.3, stiffness: 80 }}
                >
                  <SocialFollow />
                </motion.div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <Gallery />
              </motion.div>
            </MDBRow>
            <MDBRow> </MDBRow>
            {/* <Resume /> */}
          </MDBContainer>
        </article>
      </div>
    </div>
  );
}
