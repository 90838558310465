import React from "react";

import {
  MDBCard,
  MDBCol,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
  MDBCardFooter,
} from "mdb-react-ui-kit";

import { Link } from "react-router-dom";

import { motion } from "framer-motion";

export default function Card({ data }) {
  const IMG = (imgName) => {
    return require(`../Image/${imgName}`);
  };
  // console.log(content);
  return (
    <MDBCol sm="4">
      <br />
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      >
        <MDBCard>
          <MDBRipple
            rippleColor="light"
            rippleTag="div"
            className="bg-image hover-overlay"
            style={{ height: "232px" }}
          >
            <MDBCardImage
              style={{ height: "100%", width: "100%", minWidth: "100%" }}
              src={IMG(data.img)}
              fluid
              alt="..."
            />
            <Link to={`/post/${data.id}`}>
              <div
                className="mask"
                style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
              ></div>
            </Link>
          </MDBRipple>
          <MDBCardBody>
            {/* <Link to={`/post/${data.id}`}> */}
            <MDBCardTitle>{data.title}</MDBCardTitle>
            {/* </Link> */}

            <MDBCardText>{data.subtitle}</MDBCardText>
            <Link to={`/post/${data.id}`}>
              <MDBBtn href="">Read more</MDBBtn>
            </Link>

            <MDBCardFooter
              style={{ borderTop: "none" }}
              shadow="0"
              background="transparent"
            >
              <></>
            </MDBCardFooter>
          </MDBCardBody>
          {/* <MDBCardFooter>
          <small className='text-muted'>Last updated 3 mins ago</small>
        </MDBCardFooter> */}
        </MDBCard>
      </motion.div>
      <br />
    </MDBCol>
  );
}
