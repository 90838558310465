import React from "react";

import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

import { useParams, Navigate } from "react-router-dom";

import Markdown from "react-markdown";

import remarkGfm from "remark-gfm";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

import rehypeRaw from "rehype-raw";

import { motion } from "framer-motion";

import { a11yDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';

import postlist from "../posts.json";
import CodeCopyBtn from "../Components/CodeCopyButton";
// import "../Components/CodeCopyButton.css"

export const CodeBlock = {
  code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || "");
    return !inline && match ? (
      <SyntaxHighlighter
        children={String(children).replace(/\n$/, "")}
        language={match[1]}
        {...props}
      />
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
};

export default function Post() {
  const { props } = useParams();
  const validProps = parseInt(props);

  if (!validProps) {
    return <Navigate replace to="/" />;
  }
  const fetchPost = {};
  postlist.forEach((post, i) => {
    if (validProps === post.data.id) {
      fetchPost.title = post.data.title ? post.data.title : "No title given";
      fetchPost.img = post.data.img ? post.data.img : "No image given";
      fetchPost.content = post.content ? post.content : "No content given";
    }
  });
  const Pre = ({ children }) => (
    <pre className="blog-pre">
      <CodeCopyBtn>{children}</CodeCopyBtn>
      {children}
    </pre>
  );
  //   console.log(fetchPost.title);
  return (
    <MDBContainer breakpoint="sm">
      <MDBRow>
        <MDBCol md="8" className="mb-5">
          <br />
          <br />
          <br />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <Markdown
              className='post-markdown'
            //   linkTarget='_blank'
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
              children={fetchPost.content}
              components={{
                  pre: Pre,
                  code({ node, inline, className = "blog-code", children, ...props }) {
                      const match = /language-(\w+)/.exec(className || '')
                      return !inline && match ? (
                          <SyntaxHighlighter
                              style={a11yDark}
                              language={match[1]}
                              PreTag="div"
                              {...props}
                          >
                              {String(children).replace(/\n$/, '')}
                          </SyntaxHighlighter>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            />
          </motion.div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
